::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.styles_gridItem__nPXhF figcaption {
  display: none !important;
}

.styles_wrapper__1kG3C {
  background-color: transparent !important;
  min-height: 300px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* .styles_wrapper__1kG3C{
  background-color: transparent;
  min-height: 300px;
  padding-top: 40px;
  padding-bottom: 0;
}

 */

.styles_gridItem__nPXhF figure > div > img {
  opacity: 1 !important;
}

@media only screen and (max-width: 480px) {
  .styles_gridItem__nPXhF {
    min-width: 300px !important;
  }
}
